import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import type { Draft } from 'immer';
import * as api from '../../api/user-onboarding';
import { UserOnboardingState } from './types';

export const verifyIdentity = createAsyncThunk('user-onboarding/verify-identity', ({ userId }: { userId: string }) =>
  api.verifyIdentity(userId),
);

export const submitIdentity = createAsyncThunk(
  'user-onboarding/submit-identity',
  ({
    identityId,
    notifyUser,
    userId,
    verified,
  }: {
    identityId: string;
    notifyUser?: boolean;
    userId: string;
    verified?: boolean;
  }) => api.submitOnboarding(userId, 'identity', { identityId, notifyUser, verified }),
);

export const buildVerifyIdentity = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(verifyIdentity.pending, (state) => {
      state.identityError = '';
      state.status = 'pending';
    })
    .addCase(verifyIdentity.fulfilled, (state, action) => {
      state.identity = action.payload;
      state.status = 'idle';
    })
    .addCase(verifyIdentity.rejected, (state, action) => {
      state.identityError = action.error.message;
      state.identityStatus = 'failed';
      state.status = 'failed';
    });

export const buildSubmitIdentity = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(submitIdentity.pending, (state) => {
      state.identityError = '';
      state.status = 'pending';
    })
    .addCase(submitIdentity.fulfilled, (state, action) => {
      state.status = 'idle';
    })
    .addCase(submitIdentity.rejected, (state, action) => {
      state.identityError = action.error.message;
      state.identityStatus = 'failed';
      state.status = 'failed';
    });
