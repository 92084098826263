import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { floor } from 'lodash';
import { DateTime } from 'luxon';
import * as api from '../../api/user-onboarding';
import {
  buildGetBankAccounts,
  buildRefreshBankData,
  buildSubmitBankAccounts,
  getBankAccounts,
  getBankData,
  refreshBankData,
} from './bank-account';
import { buildSubmitIdentity, buildVerifyIdentity } from './identity';
import { buildSubmitRisk, buildVerifyRisk } from './risk-assessment';
import { UserOnboardingState } from './types';

const initialState: UserOnboardingState = {
  accounts: [],
  bankAccountError: '',
  bankAccountStatus: 'idle',
  riskAssessmentStatus: 'idle',
  riskAssessmentError: '',
  identityStatus: 'idle',
  identityError: '',
  status: 'idle',
};

export const startOnboarding = createAsyncThunk(
  'user-onboarding/start',
  async ({ userId }: { userId: string }, { dispatch }) => {
    let [bankData] = await Promise.all([
      dispatch(getBankData({ userId })).unwrap(),
      dispatch(getBankAccounts({ userId })).unwrap(),
    ]);

    // Refresh the bank data if it is older than 1 day
    const dataAge = DateTime.utc().diff(DateTime.fromISO(bankData.attributes.dataLastAt), 'days');
    if (dataAge.days > 1) {
      bankData = await dispatch(refreshBankData({ days: floor(dataAge.days + 2), userId })).unwrap();
    }

    return bankData;
  },
);

export const completeOnboarding = createAsyncThunk(
  'user-onboarding/complete-onboarding',
  ({ approved, userId }: { approved: boolean; userId: string }) =>
    api.submitOnboarding(userId, 'complete', { approved }),
);

const slice = createSlice({
  name: 'user-onboarding',
  initialState,
  reducers: {
    selectAccount(state, action) {
      const { index, selected } = action.payload;
      state.accounts[index].selected = selected;
      state.bankAccountError = state.accounts.some(
        (x) => x.selected && x.bankAccount.attributes.accountType !== 'transaction',
      )
        ? 'Non-transaction account selected. Are you sure you want to continue?'
        : '';
    },
    selectNoAccounts(state) {
      for (const account of state.accounts) {
        account.selected = false;
      }
    },
    setPrimaryAccount(state, action) {
      const { bankAccountId } = action.payload;
      for (const account of state.accounts) {
        account.primary = account.bankAccountId === bankAccountId;
      }
    },
  },
  extraReducers: (builder) => {
    buildGetBankAccounts(builder);
    buildRefreshBankData(builder);
    buildSubmitBankAccounts(builder);
    buildVerifyRisk(builder);
    buildSubmitRisk(builder);
    buildVerifyIdentity(builder);
    buildSubmitIdentity(builder);

    builder
      .addCase(startOnboarding.pending, () => initialState)
      .addCase(startOnboarding.fulfilled, (state, action) => {
        state.bankData = action.payload;
      })
      .addCase(startOnboarding.rejected, (state, action) => {
        state.bankAccountError = action.error.message;
        state.status = 'failed';
      });

    builder
      .addCase(completeOnboarding.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(completeOnboarding.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(completeOnboarding.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  actions: { setPrimaryAccount, selectAccount, selectNoAccounts },
  reducer,
} = slice;

export default slice;
