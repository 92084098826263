import axios from 'axios';
import { getAuthToken } from '../helpers/auth';
import { createApiError } from '../lib/errors';
import { BankAccount, BankData, CoreBankAccount, RiskAssessment, UserIdentity } from '../types/user-onboarding';

const apiRoot = process.env.REACT_APP_API_URL;
const apiClient = axios.create({ baseURL: apiRoot });

export const getBankAccounts = async (userId: string) =>
  apiClient
    .get<{ data: BankAccount[] }>(`/users/${userId}/bank-data/accounts`, {
      headers: { Authorization: await getAuthToken() },
    })
    .then((x) => x.data.data)
    .catch((err) => {
      throw createApiError(err, 'Get bank accounts failed');
    });

export const getBankData = async (userId: string) =>
  apiClient
    .get<{ data: BankData }>(`/users/${userId}/bank-data`, {
      headers: { Authorization: await getAuthToken() },
    })
    .then((x) => x.data.data)
    .catch((err) => {
      throw createApiError(err, 'Get bank data failed');
    });

export const refreshBankData = async (userId: string, days = 1) =>
  apiClient
    .post<{ data: BankData }>(`/users/${userId}/bank-data`, undefined, {
      headers: { Authorization: await getAuthToken() },
      params: { days, dataType: 'json' },
    })
    .then((x) => x.data.data)
    .catch((err) => {
      throw createApiError(err, 'Refresh bank data failed');
    });

export const submitBankAccounts = async (userId: string, bankAccounts: BankAccount[]) =>
  apiClient
    .put<{ data: CoreBankAccount[] }>(
      `/users/${userId}/bank-accounts`,
      {
        data: bankAccounts.map((account) => ({
          attributes: {
            accountBsb: account.attributes.accountBsb,
            accountName: account.attributes.accountName,
            accountNumber: account.attributes.accountNumber,
            accountType: account.attributes.accountType,
            bankDataAccountId: account.id,
            bankName: account.attributes.institutionName,
          },
        })),
      },
      { headers: { Authorization: await getAuthToken() } },
    )
    .then((x) => x.data.data)
    .catch((err) => {
      throw createApiError(err, 'Submit user bank accounts failed');
    });

export const submitOnboarding = async (
  userId: string,
  step: 'bank-account' | 'identity' | 'risk-assessment' | 'complete',
  attributes?: unknown,
) =>
  apiClient
    .post<{ data: BankAccount[] }>(
      `/onboarding/${userId}/${step}`,
      { data: { attributes } },
      { headers: { Authorization: await getAuthToken() } },
    )
    .then((res) => res.data)
    .catch((err) => {
      throw createApiError(err, 'Submit onboarding failed');
    });

export const verifyRisk = async (userId: string, bankAccountIds: string[]) =>
  apiClient
    .post<{ data: RiskAssessment[] }>(
      `/onboarding/${userId}/verify-risk`,
      { data: bankAccountIds.map((bankAccountId) => ({ attributes: { bankAccountId } })) },
      { headers: { Authorization: await getAuthToken() } },
    )
    .then((x) => x.data.data)
    .catch((err) => {
      throw createApiError(err, 'Verify risk failed');
    });

export const verifyIdentity = async (userId: string) =>
  apiClient
    .post<{ data: UserIdentity }>(`/onboarding/${userId}/verify-identity`, undefined, {
      headers: { Authorization: await getAuthToken() },
    })
    .then((x) => x.data.data)
    .catch((err) => {
      throw createApiError(err, 'Verify identity failed');
    });
