/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/restrict-template-expressions, no-await-in-loop */
import axios from 'axios';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import toast from 'react-hot-toast';
import { getAuthToken } from '../helpers/auth';
import { mapResources } from '../lib/apiHelpers';
import { createApiError } from '../lib/errors';
import type { TransactionAttributes, TransactionStatus } from '../types/transaction';

type ITransactionStatus = typeof TransactionStatus[number];

const apiRoot = process.env.REACT_APP_API_URL;
const apiClient = axios.create({ baseURL: apiRoot });

export const createTransaction = async (attributes: Partial<TransactionAttributes>) => {
  try {
    const token = await getAuthToken();
    await apiClient.post('/transactions', attributes, { headers: { Authorization: token } });
  } catch (err) {
    throw createApiError(err, 'Cannot create transaction');
  }
};

export const getAllTransactions = async (userId: string): Promise<TransactionAttributes[]> => {
  let pageKey: string | undefined;
  const transactions: TransactionAttributes[] = [];

  try {
    const token = await getAuthToken();

    do {
      const { data } = await apiClient.get<{
        data: { id: string; type: string; attributes: TransactionAttributes }[];
        meta: { pageKey: string };
      }>('/transactions', { params: { pageKey, userId }, headers: { Authorization: token } });

      transactions.push(...data.data.map(({ id, type, attributes }) => ({ id, type, ...attributes })));
      pageKey = data.meta.pageKey;
    } while (!isEmpty(pageKey));

    return transactions;
  } catch (err) {
    throw createApiError(err, 'Cannot get transactions');
  }
};

class TransactionApi {
  async getTransactionsByUserId(userId: string): Promise<TransactionAttributes[]> {
    try {
      const res = await fetch(`${apiRoot}/transactions?userId=${userId}`, {
        method: 'GET',
        headers: {
          Authorization: await getAuthToken(),
        },
      });

      return mapResources(await res.json());
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Cannot get transactions'));
    }
    return [];
  }

  async getTransactionsByPaymentType(
    paymentType: string,
    status: string,
    startDate: string,
    endDate: string,
  ): Promise<TransactionAttributes[]> {
    let pageKey: unknown | undefined;
    const transactions: TransactionAttributes[] = [];
    do {
      const query = new URLSearchParams({
        paymentType,
        status,
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
      });

      if (!isEmpty(pageKey)) {
        query.append('pageKey', JSON.stringify(pageKey));
      }

      const res = await fetch(`${apiRoot}/transactions/by-payment-type?${query}`, {
        method: 'GET',
        headers: {
          Authorization: await getAuthToken(),
        },
      });
      const body = (await res.json()) as {
        data: { attributes: TransactionAttributes; id: string; type: string }[];
        meta: { pageKey: unknown };
      };

      if (body.data) {
        transactions.push(...body.data.map(({ id, attributes }) => ({ ...attributes, id })));
      }

      pageKey = body.meta?.pageKey;
    } while (!isEmpty(pageKey));

    return transactions;
  }

  async reconcileTransaction({
    id,
    status,
    statusReason,
    updatedBy,
  }: {
    id: string;
    status: ITransactionStatus;
    statusReason?: string;
    updatedBy?: string;
  }): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/transactions/${id}/reconcile`, {
        method: 'POST',
        headers: {
          Authorization: await getAuthToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status,
          statusReason,
          updatedBy,
        }),
      });
      if (res.status !== 204) {
        const resJson = await res.json();
        toast.error(get(resJson, 'errors[0].title', 'Reconciling transaction failed'));
        return {
          success: false,
        };
      }
      toast.success('Reconciling transaction successfully');
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Reconciling transaction failed'));
      return {
        success: false,
      };
    }
    return {
      success: true,
    };
  }

  async updateTransaction(transaction: TransactionAttributes): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/transactions/${transaction.id}`, {
        method: 'PUT',
        headers: {
          Authorization: await getAuthToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transaction),
      });
      if (res.status !== 200) {
        const resJson = await res.json();
        toast.error(get(resJson, 'errors[0].title', 'Cannot update this transaction'));
        return {
          success: false,
        };
      }
      toast.success('Updating transaction successfully');
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Cannot update this transaction'));
      return {
        success: false,
      };
    }
    return {
      success: true,
    };
  }
}

export const transactionApi = new TransactionApi();
