import { combineReducers } from '@reduxjs/toolkit';
import { adminApi } from '../api';
import { reducer as transactionReducer } from '../slices/transaction';
import { reducer as userReducer } from '../slices/user';
import { reducer as userOnboardingReducer } from '../slices/user-onboarding';

const rootReducer = combineReducers({
  user: userReducer,
  userOnboarding: userOnboardingReducer,
  transaction: transactionReducer,
  [adminApi.reducerPath]: adminApi.reducer,
});

export default rootReducer;
