import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import type { Draft } from 'immer';
import { get } from 'lodash';
import pRetry, { AbortError } from 'p-retry';
import * as api from '../../api/user-onboarding';
import { UserAccount, UserOnboardingState } from './types';

export const getBankAccounts = createAsyncThunk('user-onboarding/get-bank-accounts', ({ userId }: { userId: string }) =>
  api.getBankAccounts(userId),
);

export const getBankData = createAsyncThunk('user-onboarding/get-bank-data', ({ userId }: { userId: string }) =>
  api.getBankData(userId),
);

export const refreshBankData = createAsyncThunk(
  'user-onboarding/refresh-bank-data',
  ({ days, userId }: { days: number; userId: string }) =>
    pRetry(() => api.refreshBankData(userId, days), {
      onFailedAttempt: (err) => {
        // If it's not an API timeout error, then keep retrying, otherwise abort.
        const canRetry =
          axios.isAxiosError(err) &&
          (err.response?.status === 504 || get(err.response?.data, 'errors[0].code') === 'DataRefreshInProgressError');

        if (!canRetry) {
          throw new AbortError(err);
        }
      },
    }),
);

export const submitBankAccount = createAsyncThunk(
  'user-onboarding/submit-bank-account',
  ({ userId }: { userId: string }) => api.submitOnboarding(userId, 'bank-account'),
);

export const submitBankAccounts = createAsyncThunk(
  'user-onboarding/submit-bank-accounts',
  async ({ accounts, userId }: { accounts: UserAccount[]; userId: string }) =>
    api.submitBankAccounts(
      userId,
      accounts.filter((x) => x.selected).map((x) => x.bankAccount),
    ),
);

export const buildGetBankAccounts = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(getBankAccounts.pending, (state) => {
      state.accounts = [];
      state.bankAccountError = '';
      state.status = 'pending';
    })
    .addCase(getBankAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload.map((bankAccount) => ({
        bankAccount,
        bankAccountId: bankAccount.id,
        selected: bankAccount.attributes.accountType === 'transaction',
        primary: false,
      }));

      state.status = 'idle';

      if (!state.accounts?.length) {
        state.bankAccountError = 'No valid bank accounts available';
        state.status = 'failed';
      }
    })
    .addCase(getBankAccounts.rejected, (state, action) => {
      state.bankAccountError = action.error.message;
      state.bankAccountStatus = 'failed';
      state.status = 'failed';
    });

export const buildRefreshBankData = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(refreshBankData.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(refreshBankData.fulfilled, (state) => {
      state.status = 'idle';
    })
    .addCase(refreshBankData.rejected, (state, action) => {
      state.bankAccountError = action.error.message;
      state.bankAccountStatus = 'failed';
      state.status = 'failed';
    });

export const buildSubmitBankAccounts = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(submitBankAccounts.pending, (state) => {
      state.bankAccountError = '';
      state.status = 'pending';
    })
    .addCase(submitBankAccounts.fulfilled, (state, action) => {
      for (const account of state.accounts) {
        // Store core bank account id
        account.coreBankAccountId = action.payload.find(
          (x) => x.attributes.bankDataAccountId === account.bankAccount.id,
        )?.id;
      }
      state.bankAccountStatus = 'succeeded';
      state.status = 'idle';
    })
    .addCase(submitBankAccounts.rejected, (state, action) => {
      state.bankAccountError = action.error.message;
      state.bankAccountStatus = 'failed';
      state.status = 'failed';
    });
