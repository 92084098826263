/* eslint-disable class-methods-use-this */
import axios from 'axios';
import createError from 'err-code';
import { get } from 'lodash';
import moment from 'moment';
import toast from 'react-hot-toast';
import { getAuthToken } from '../helpers/auth';
import { mapResource, mapResources } from '../lib/apiHelpers';
import { createApiError } from '../lib/errors';
import { BankAccount } from '../types/bankAccount';
import { Income } from '../types/income';
import type { User, UserStatus } from '../types/users';

const apiRoot = process.env.REACT_APP_API_URL;
const apiClient = axios.create({ baseURL: apiRoot });

export const getUser = async (userId: string): Promise<User> => {
  const { data } = await apiClient
    .get(`/users/${userId}`, {
      headers: { Authorization: await getAuthToken() },
      params: { include: 'locale' },
    })
    .catch((err) => {
      throw createApiError(err, 'Get user failed');
    });

  return mapResource(data);
};

export const updateUser = async (userId: string, user: Partial<User>) => {
  try {
    // Delete middle name if it is empty
    user.middleName = user.middleName === '' ? null : user.middleName;
    await apiClient.put(`/users/${userId}`, user, { headers: { Authorization: await getAuthToken() } });
    user.middleName = user.middleName === null ? undefined : user.middleName;
  } catch (err) {
    throw createApiError(err, 'Update user failed');
  }
};

export const updateUserIncome = async (userId: string, incomeId: string, income: Partial<Income>) => {
  try {
    await apiClient.patch(
      `/users/${userId}/incomes/${incomeId}`,
      { data: { attributes: income } },
      { headers: { Authorization: await getAuthToken() } },
    );
  } catch (err) {
    throw createApiError(err, 'Update user income failed');
  }
};

export const updateUserBalanceLimit = async (userId: string, balanceLimit: number) =>
  apiClient
    .patch(`/users/${userId}/balance-limit`, { balanceLimit }, { headers: { Authorization: await getAuthToken() } })
    .catch((err) => {
      throw createApiError(err, 'Update user balance limit failed');
    });

export const updateUserMobileNumber = async (
  userId: string,
  mobileNumber: string,
  mobileNumberDisconnected?: boolean,
) =>
  apiClient
    .patch(
      `/users/${userId}/mobile-number`,
      { mobileNumber, mobileNumberDisconnected },
      { headers: { Authorization: await getAuthToken() } },
    )
    .catch((err) => {
      throw createApiError(err, 'Update user mobile number failed');
    });

class UserApi {
  async deleteUser(userId: string): Promise<void> {
    await fetch(`${apiRoot}/users/${userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: await getAuthToken(),
        'Content-Type': 'application/json',
      },
    }).then(async (res) => {
      if (!res.ok) {
        const body = await res.json();
        throw new Error(get(body, 'errors[0].title', 'Cannot delete user'));
      }
      return undefined;
    });
  }

  async getUsers(
    filter: Record<string, unknown> = {},
    range = '[0,9]',
    sort = 'sort=["createdAt","DESC"]',
  ): Promise<User[]> {
    try {
      const res = await fetch(
        `${apiRoot}/users?filter=${encodeURIComponent(JSON.stringify(filter))}&range=${encodeURIComponent(
          range,
        )}&sort=${encodeURIComponent(sort)}`,
        {
          method: 'GET',
          headers: {
            Authorization: await getAuthToken(),
          },
        },
      );

      return mapResources(await res.json());
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Cannot get users'));
    }
    return [];
  }

  async getUsersInArrears(
    comparer: string,
    frequencyCount: number,
    pageKey?: Record<string, unknown>,
  ): Promise<{ user: User[]; meta: { pageKey: Record<string, unknown> } }> {
    const pageKeyQuery = pageKey ? `&pageKey=${encodeURIComponent(JSON.stringify(pageKey))}` : '';
    try {
      const res = await fetch(
        `${apiRoot}/users/balance-overdue?comparer=${comparer}&frequencyCount=${frequencyCount}${pageKeyQuery}`,
        {
          method: 'GET',
          headers: {
            Authorization: await getAuthToken(),
          },
        },
      );
      const jsonResponse = (await res.json()) as {
        data: { attributes: User; id: string; type: string }[];
        meta: { pageKey: Record<string, unknown> };
      };
      const { meta } = jsonResponse;

      return { user: mapResources(jsonResponse), meta };
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Cannot get in arrears users'));
    }
    return {
      user: [],
      meta: {
        pageKey: {},
      },
    };
  }

  async getBankAccount(userId: string): Promise<BankAccount[]> {
    try {
      const res = await fetch(`${apiRoot}/users/${userId}/bank-accounts`, {
        method: 'GET',
        headers: {
          Authorization: await getAuthToken(),
        },
      });

      return mapResources(await res.json());
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Cannot get bank accounts'));
    }
    return [];
  }

  async getBankData(userId: string): Promise<{
    data?: { meta: { reportUrl: string }; attributes: { refreshLastAt: string } };
  }> {
    try {
      const res = await fetch(`${apiRoot}/users/${userId}/bank-data`, {
        method: 'GET',
        headers: {
          Authorization: await getAuthToken(),
        },
      });

      return await res.json();
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', 'Cannot get bank data'));
    }

    return {};
  }

  async postBankData(
    userId: string,
    days: number,
  ): Promise<{
    data: { meta: { reportUrl: string }; attributes: { refreshLastAt: string } };
  }> {
    const res = await fetch(`${apiRoot}/users/${userId}/bank-data?days=${days}`, {
      method: 'POST',
      headers: { Authorization: await getAuthToken() },
    });

    const body = await res.json();

    if (!res.ok) {
      throw createError(
        new Error(get(body, 'errors[0].title', 'Refreshing bank statement failed')),
        get(body, 'errors[0].code', 'BankDataError'),
        { status: res.status },
      );
    }

    return body;
  }

  async updateUserStatus(
    userId: string,
    status: (typeof UserStatus)[number],
    statusReason: string,
    updatedBy?: string,
  ): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/users/${userId}/status`, {
        method: 'PATCH',
        headers: {
          Authorization: await getAuthToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status,
          statusReason,
          updatedBy,
        }),
      });
      if (res.status !== 200) {
        return {
          success: false,
        };
      }
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', "Cannot update user's status"));
      return {
        success: false,
      };
    }
    return {
      success: true,
    };
  }

  async updateCollectionReminderPausedUntil(
    userId: string,
    collectionReminderPausedUntil: string,
  ): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/users/${userId}`, {
        method: 'PATCH',
        headers: {
          Authorization: await getAuthToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          collectionReminderPausedUntil: collectionReminderPausedUntil
            ? moment.utc(collectionReminderPausedUntil).toISOString()
            : null,
        }),
      });
      if (res.status !== 200) {
        toast.error("Updating user's collection reminder failed");
        return {
          success: false,
        };
      }
      toast.success(
        collectionReminderPausedUntil
          ? "User's collection reminders have paused"
          : "User's collection reminders have resumed",
      );
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', "Cannot update user's collection reminder pause date"));
      return {
        success: false,
      };
    }
    return {
      success: true,
    };
  }

  async userSplitPayment(
    userId: string,
    params: {
      count: string;
      amount: string;
      fee: string;
      pauseCollectionReminder: boolean;
      cancelAllPendingTransactions: boolean;
    },
  ): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/users/${userId}/split-payments`, {
        method: 'POST',
        headers: {
          Authorization: await getAuthToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      const resJson = await res.json();
      if (res.status !== 200) {
        toast.error(get(resJson, 'errors[0].title', "Cannot split user's payment"));
        return {
          success: false,
        };
      }
      toast.success("Split user's payment success");
    } catch (err) {
      toast.error(get(err, 'body.errors[0].title', "Cannot split user's payment"));
      return {
        success: false,
      };
    }
    return {
      success: true,
    };
  }
}

export const userApi = new UserApi();
