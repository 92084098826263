import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import * as json2csv from 'json2csv';
import { merge, pick } from 'lodash';
import * as api from '../api/user-old';
import { userApi } from '../api/user-old';
import type { AppThunk } from '../store';
import type { User } from '../types/users';
import objFromArray from '../utils/objFromArray';
import { getTransactionsByUserId } from './transaction';
import { Income } from '../types/income';

interface UserState {
  users: {
    byId: Record<string, User>;
    allIds: string[];
  };
  allUsers: {
    byId: Record<string, User>;
    allIds: string[];
  };
  status: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  targetUserId: string;
  filter: Record<string, string>;
  pageKey?: Record<string, unknown>;
}

const initialState: UserState = {
  users: {
    byId: {},
    allIds: [],
  },
  allUsers: {
    byId: {},
    allIds: [],
  },
  filter: {},
  status: 'idle',
  targetUserId: '',
};

export const getUser = createAsyncThunk('users/get', ({ userId }: { userId: string }) => api.getUser(userId));

export const updateUser = createAsyncThunk(
  'users/update',
  ({ user, userId }: { userId: string; user: Partial<User> }) =>
    api.updateUser(userId, user).then(() => ({ userId, user })),
);

export const updateUserIncome = createAsyncThunk(
  'users/update-income',
  ({ income, incomeId, userId }: { userId: string; incomeId: string; income: Partial<Income> }) =>
    api.updateUserIncome(userId, incomeId, income).then(() => ({ userId, income })),
);

export const updateUserBalanceLimit = createAsyncThunk(
  'users/update-balance-limit',
  ({ userId, balanceLimit }: { userId: string; balanceLimit: number }) =>
    api.updateUserBalanceLimit(userId, balanceLimit).then(() => ({ userId, balanceLimit })),
);

export const updateUserMobileNumber = createAsyncThunk(
  'users/update-mobile-number',
  (
    {
      userId,
      mobileNumber,
      mobileNumberDisconnected,
    }: {
      userId: string;
      mobileNumber: string;
      mobileNumberDisconnected?: boolean;
    },
    { dispatch },
  ) =>
    api
      .updateUserMobileNumber(userId, mobileNumber, mobileNumberDisconnected)
      .then(() => dispatch(getUser({ userId }))),
);

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading(state: UserState, action: PayloadAction): void {
      state.status = 'loading';
    },
    error(state: UserState, action: PayloadAction): void {
      state.status = 'error';
    },
    setPageKey(state: UserState, action: PayloadAction<Record<string, unknown>>): void {
      state.pageKey = action.payload;
    },
    deleteUser(state: UserState, action: PayloadAction<{ userId: string }>): void {
      delete state.allUsers.byId[action.payload.userId];
      state.allUsers.allIds = Object.keys(state.allUsers.byId);
      delete state.users.byId[action.payload.userId];
      state.users.allIds = Object.keys(state.users.byId);
    },
    getUsers(state: UserState, action: PayloadAction<User[]>): void {
      const users = action.payload;
      state.status = 'success';
      state.users.byId = objFromArray(users);
      state.users.allIds = Object.keys(state.users.byId);
    },
    getAllUsers(state: UserState, action: PayloadAction<User[]>): void {
      const users = action.payload;
      state.allUsers.byId = objFromArray(users);
      state.allUsers.allIds = Object.keys(state.allUsers.byId);
    },
    updateBalanceLimit(state: UserState, action: PayloadAction<{ userId: string; balanceLimit: number }>): void {
      const { userId, balanceLimit } = action.payload;
      state.users.byId[userId] = {
        ...state.users.byId[userId],
        balanceLimit,
      };
    },
    updateMobileNumber(
      state: UserState,
      action: PayloadAction<{
        userId: string;
        mobileNumber: string;
      }>,
    ): void {
      const { userId, mobileNumber } = action.payload;
      if (state.users.byId[userId]) {
        state.users.byId[userId] = {
          ...state.users.byId[userId],
          mobileNumber,
        };
      }
    },
    updateUser(state: UserState, action: PayloadAction<{ user: Partial<User>; userId: string }>): void {
      const { user, userId } = action.payload;
      const existingUser = state.users.byId[userId];
      if (existingUser) {
        state.users.byId[userId] = {
          ...existingUser,
          ...user,
        };
      }
    },
    updateCollectionReminderPausedUntil(
      state: UserState,
      action: PayloadAction<{ userId: string; collectionReminderPausedUntil: string }>,
    ): void {
      const { userId, collectionReminderPausedUntil } = action.payload;
      state.users.byId[userId].collectionReminderPausedUntil = collectionReminderPausedUntil;
    },
    updateFilter(state: UserState, action: PayloadAction<Record<string, string>>): void {
      const filter = action.payload;
      state.filter = filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      const user = action.payload;
      state.status = 'success';
      state.targetUserId = user.id;
      if (!state.users.allIds.includes(user.id)) {
        state.users.allIds.push(user.id);
      }
      state.users.byId[user.id] = user;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const { user, userId } = action.payload;
      state.users.byId[userId] = { ...state.users.byId[userId], ...user };
    });
    builder.addCase(updateUserIncome.fulfilled, (state, action) => {
      const { income, userId } = action.payload;
      const user = state.users.byId[userId];
      state.users.byId[userId] = merge(user, { income });
    });
    builder.addCase(updateUserBalanceLimit.fulfilled, (state, action) => {
      const { userId, balanceLimit } = action.payload;
      state.users.byId[userId] = { ...state.users.byId[userId], balanceLimit };
    });
  },
});

export const { reducer } = slice;

export const deleteUser =
  ({ userId, onSuccess, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      await userApi.deleteUser(userId);
      dispatch(slice.actions.deleteUser({ userId }));
      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

const filtersUsers = (users: User[], query: string, filters: any): User[] =>
  users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['email', 'firstName', 'lastName', 'middleName', 'mobileNumber'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property]?.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

export const getUsers =
  (filter?: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const userState = getState().user;
    const allUsers = userState.allUsers.allIds.map((id) => userState.allUsers.byId[id]);

    if (filter) {
      const filteredUsers = filtersUsers(allUsers, filter, {});
      if (filteredUsers.length) {
        dispatch(slice.actions.getUsers(filteredUsers));
      } else {
        dispatch(slice.actions.loading());
        const data = await userApi.getUsers({ mobileNumber: filter });
        dispatch(slice.actions.getUsers(data));
      }
    } else if (allUsers.length) {
      dispatch(slice.actions.getUsers(allUsers));
    } else {
      dispatch(slice.actions.loading());
      const data = await userApi.getUsers({});
      dispatch(slice.actions.getAllUsers(data));
      dispatch(slice.actions.getUsers(data));
      dispatch(slice.actions.setPageKey());
    }
  };

export const getUsersInArrears =
  (comparer: string, frequencyCount: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.loading());
    const { user, meta } = await userApi.getUsersInArrears(comparer, frequencyCount);
    dispatch(slice.actions.getUsers(user));
    dispatch(slice.actions.setPageKey(meta.pageKey));
  };

export const filterMoreUsers =
  (comparer: string, frequencyCount: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const {
      pageKey,
      users: { allIds, byId },
    } = state.user;
    const currentUsers = allIds.map((id) => byId[id]);
    dispatch(slice.actions.loading());
    const { user, meta } = await userApi.getUsersInArrears(comparer, frequencyCount, pageKey);
    dispatch(slice.actions.getUsers([...currentUsers, ...user]));
    dispatch(slice.actions.setPageKey(meta.pageKey));
  };

export const updateUserStatus =
  ({ status, statusReason, userId, updatedBy, onComplete }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await userApi.updateUserStatus(userId, status, statusReason, updatedBy);
    onComplete({ success });
    if (success) {
      dispatch(slice.actions.updateUser({ user: { status, statusReason, updatedBy }, userId }));
    }
  };

export const updateCollectionReminderPausedUntil =
  ({ userId, collectionReminderPausedUntil }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await userApi.updateCollectionReminderPausedUntil(userId, collectionReminderPausedUntil);
    if (success) {
      dispatch(slice.actions.updateCollectionReminderPausedUntil({ userId, collectionReminderPausedUntil }));
    }
  };

export const splitPayment =
  ({
    userId,
    params,
    callback,
  }: {
    userId: string;
    params: {
      count: string;
      amount: string;
      fee: string;
      pauseCollectionReminder: boolean;
      cancelAllPendingTransactions: boolean;
    };
    callback: (status: boolean) => void;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await userApi.userSplitPayment(userId, params);
    callback?.(success);
    if (success) {
      dispatch(getTransactionsByUserId(userId));
      await dispatch(getUser({ userId }));
    }
  };

export const exportUserCSV =
  (): AppThunk =>
  (dispatch, getState): void => {
    const { users } = getState().user;
    const userMappingToCSV = users.allIds.map((userId) => {
      const user = users.byId[userId];
      return pick(user, [
        'id',
        'email',
        'mobileNumber',
        'firstName',
        'middleName',
        'lastName',
        'balanceBook',
        'balanceCurrent',
        'balanceLimit',
        'balanceOverdue',
        'balanceOverdueAt',
        'collectionReminderPausedUntil',
        'incomeFrequency',
        'incomeNextDate',
        'status',
        'statusReason',
        'transactionLastAt',
        'transactionLastId',
      ]);
    });

    const csv = json2csv.parse(userMappingToCSV);

    fileDownload(csv, 'users.csv');
  };

export default slice;
