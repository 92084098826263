import { isArray, map, mapValues, merge } from 'lodash';

interface Resources<T = unknown> {
  data: {
    id: string;
    type?: string;
    attributes: T;
  }[];
}

interface Resource<T = unknown> {
  data: {
    id: string;
    type?: string;
    attributes: T;
    relationships?: Record<string, Resource<unknown>>;
  };
  included?: Resource<unknown>['data'][];
}

export const mapResource = <T>(resource: Resource<T>): T => {
  const { data, included = [] } = resource;

  const relationships = mapValues(data.relationships, (item, key) => {
    const itemData = isArray(item.data) ? item.data[0] : item.data;

    if (!itemData) return {};

    const itemIncluded = included.find((x) => x.id === itemData.id);

    return merge(
      {
        id: itemData.id,
        type: itemData.type,
      },
      itemData.attributes,
      itemIncluded?.attributes,
    );
  });

  return merge(
    {
      id: data.id,
      type: data.type,
    },
    data.attributes,
    relationships,
  );
};

export const mapResources = <T>(resource: Resources<T>): T[] => {
  const { data } = resource;
  return map(data, (x) => merge({ id: x.id, type: x.type }, x.attributes));
};
