import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import type { Draft } from 'immer';
import { orderBy } from 'lodash';
import * as api from '../../api/user-onboarding';
import { UserAccount, UserOnboardingState } from './types';

export const verifyRisk = createAsyncThunk(
  'user-onboarding/verify-risk',
  ({ accounts, userId }: { accounts: UserAccount[]; userId: string }) =>
    api.verifyRisk(
      userId,
      accounts.filter((x) => x.selected).map((x) => x.bankAccount.id),
    ),
);

export const submitRisk = createAsyncThunk(
  'user-onboarding/submit-risk',
  async ({
    bankAccountId,
    riskAssessmentId,
    userId,
  }: {
    bankAccountId: string;
    riskAssessmentId: string;
    userId: string;
  }) => {
    await api.submitOnboarding(userId, 'bank-account', { bankAccountId });
    await api.submitOnboarding(userId, 'risk-assessment', { riskAssessmentId });
  },
);

export const buildVerifyRisk = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(verifyRisk.pending, (state) => {
      state.riskAssessmentError = '';
      state.status = 'pending';
    })
    .addCase(verifyRisk.fulfilled, (state, action) => {
      const riskAssessments = orderBy(
        action.payload,
        ['attributes.approvedAmount', 'attributes.incomeAverage'],
        ['desc', 'desc'],
      );

      for (const [index, riskAssessment] of riskAssessments.entries()) {
        const account = state.accounts.find((x) => x.bankAccountId === riskAssessment.attributes.bankAccountId);
        if (account) {
          account.riskAssessment = riskAssessment;
          account.riskAssessmentId = riskAssessment.id;
          if (index === 0) {
            account.primary = true;
          }
        }
      }

      state.status = 'idle';
    })
    .addCase(verifyRisk.rejected, (state, action) => {
      state.riskAssessmentError = action.error.message;
      state.riskAssessmentStatus = 'failed';
      state.status = 'failed';
    });

export const buildSubmitRisk = (builder: ActionReducerMapBuilder<Draft<UserOnboardingState>>) =>
  builder
    .addCase(submitRisk.pending, (state) => {
      state.riskAssessmentError = '';
      state.status = 'pending';
    })
    .addCase(submitRisk.fulfilled, (state) => {
      state.riskAssessmentStatus = 'succeeded';
      state.status = 'idle';
    })
    .addCase(submitRisk.rejected, (state, action) => {
      state.riskAssessmentError = action.error.message;
      state.riskAssessmentStatus = 'failed';
      state.status = 'failed';
    });
