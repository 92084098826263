import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthToken } from '../helpers/auth';

// Define a service using a base URL and expected endpoints
export const adminApi = createApi({
  reducerPath: 'admin-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['plan', 'plan/installment', 'transaction', 'user'],
});
